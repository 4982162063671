import React, { Component } from "react";
import AuthService from "../services/auth.service";
import DataService from "../services/data.service";
import SendMQTT from "./moderator-sendMQTT"


export default class ModeratorMain extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content: []
        };
    }

    componentDidMount() {
        let currentUser = AuthService.getCurrentUser()
        if (currentUser !== null) {
            DataService.getModules(currentUser.id).then(
                (response) => {
                    this.setState({
                        content: response.data
                    });
                },
                error => {
                    this.setState({
                        content: []
                    });
                }
            );
        } else {
            this.setState({
                content: []
            });
        }
    }

    render() {
        const myContent = this.state.content

        return (
            <>
                {myContent && myContent.length > 0 && (
                    <span className="viewcard41">
                        <SendMQTT myContent={myContent} />
                    </span>
                )}
                {myContent.length === 0 && (
                    <span className="viewcard21">
                        <h3>No Access, please login again.</h3>
                    </span>
                )}
            </>
        );
    }
}
