import React from "react";
import AuthService from "../services/auth.service";
import DataService from "../services/data.service";
import Box from '@material-ui/core/Box';
// import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import './lampCard.css';

const useStyles = makeStyles({
    root: { 
      width: 190,
    },
    btn: {
        color: 'white',
    }
  });

const LampCard2 = ({ IMEI, data }) => {
    const classes = useStyles();
    const user = AuthService.getCurrentUser();
    let isModerator = false;
    //let myDiodeData = (data.DV/8);
    const [value, setValue] = React.useState(data.data[4]/100);

    const lampCardDisplayClassName = (data.status==="G" ? 'lampCard-display' : (data.status==="Y" ? 'lampCard-display-yellow' : 'lampCard-display-red'));

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if (user) {
        isModerator = user.roles.includes("ROLE_MODERATOR");
    }

    const handleClick = () => {
        //alert('Button ' + IMEI + ':' + lamp + ' = ' + value + ' clicked');
        DataService.MQTTPub(IMEI,'{"ID":' + data.ID + ',"PWM":' + value + '}');
    }

    return (
        <div className='lampCard-container'>
            <div className='cardCard-title-container'>
                <div className='lampCard-title'>
                    LAMPE {data.ID}
                </div>
            </div>
            <div className='lampCard-display-container'>  
                { data.data[4] && (<text className={lampCardDisplayClassName}>{(data.data[4]/100).toFixed(0)}% </text>)}
            </div>
            <div className='lampCard-list-container'>
                <div className='lampCard-list'>Temperatur: {data.data[0] && (data.data[0].toFixed(0))}°C</div>
                <div className='lampCard-list'>Lys: {data.data[21] && (data.data[21].toFixed(0))}</div>
                { false && <div className='lampCard-list'>RH: {data.data[1] && (data.data[1].toFixed(0))}%</div> }
                <div className='lampCard-list'>Strøm: {data.data[2] && ((data.data[2]/1000).toFixed(1))}A</div>
            </div>
           {isModerator && false && (
                <Box div className={classes.root} color="background.paper" p={1}>
                    <Slider value={value} onChange={handleChange} valueLabelDisplay={true} aria-labelledby="continuous-slider" />
                    <Button className={classes.btn} size="small" onClick={handleClick}>Set lysstyrke</Button>            
                </Box>
            )}
        </div>
    )
}

export default LampCard2