import React, { Component } from "react";
import AuthService from "../services/auth.service";
import DataService from "../services/data.service";
import MySystemsForm from "./mySystemsForm.component";

export default class BoardUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content: [],
            clusters: []
        };
    }

    componentDidMount() {
        this.getData();

        setInterval(this.getData, 15000);  // Update every 15th secound
    }

    getData = () => {
        let currentUser = AuthService.getCurrentUser()
        if (currentUser !== null) {
            DataService.getSystems(currentUser.id).then(response => {
                DataService.getClusters(currentUser.id).then(Clusters => {
                    this.setState({
                        content: response.data,
                        clusters: Clusters.data
                    });
                },
                    error => {
                        this.setState({
                            content: [],
                            clusters: []
                        });
                    }
                );
            },
                error => {
                    this.setState({
                        content: [],
                        clusters: []
                    });
                }
            );
        } else {
            this.setState({
                content: [],
                clusters: []
            });
        }
    }

    render() {
        const myContent = this.state.content
        const myClusters = this.state.clusters

        return (
            <div className="grid_container">
                {myContent && myContent.length > 0 && (
                    <MySystemsForm myContent={myContent} myClusters={myClusters} />
                )}
                {myContent.length === 0 && (
                    <span className="viewcard21">
                        <h3>No Access, please login again.</h3>
                    </span>
                )}
            </div>
        );
    }
}
