import axios from 'axios';
import authHeader from './auth-header';

// const API_URL = '/api/data/';
const  API_URL = '/api/data/';

class DataService {

    getModules(id) {
        return axios.get(API_URL + 'modules?ID=' + id, { headers: authHeader() });
    }

    getSystems(id) {
        return axios.get(API_URL + 'systems?ID=' + id, { headers: authHeader() });
    }

    getClusters(id) {
        return axios.get(API_URL + 'clusters?ID=' + id, { headers: authHeader() });
    }

    getGNSS(id,IMEI,days) {
        return axios.get(API_URL + 'GNSSdata?ID=' + id + '&IMEI=' + IMEI + '&DAYS=' + days, { headers: authHeader() });
    }

    getSensor(id,IMEI,lamp,days) {
        return axios.get(API_URL + 'SensorData?ID=' + id + '&IMEI=' + IMEI + '&lamp=' + lamp + '&DAYS=' + days, { headers: authHeader() });
    }

    getSensorPeriod(id,IMEI,lamp,fromDate,toDate) {
        return axios.get(API_URL + 'SensorData?ID=' + id + '&IMEI=' + IMEI + '&lamp=' + lamp + '&fromDate=' + fromDate + '&toDate=' + toDate, { headers: authHeader() });
    }

    MQTTPub(IMEI,pubMsg) {
        return axios.get(API_URL + 'MQTTPub?IMEI=' + IMEI + '&pubMsg=' + pubMsg, { headers: authHeader() });
    }

}

export default new DataService();