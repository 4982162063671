import React, { useState, useRef } from "react";
import ReactMapGL, { Marker, FlyToInterpolator } from "react-map-gl";
import useSupercluster from "use-supercluster";

const MapboxGLMerdeMap = ({ myContent }) => {
    const [viewport, setViewport] = useState({
        latitude: 59.422490,
        longitude: 10.456476,
        width: "100%",
        height: 640, //"calc(100% - 50px)",
        zoom: 3
      });
      const mapRef = useRef();
    
    const points = myContent.map((merde) => ({
      type: "Feature",
      properties: { cluster: false, merdeId: merde.id, merdeNick: merde.nick, status: (merde.status ? merde.status.substring(1, 2).toUpperCase() : '-') },
      geometry: merde.geometry 
    }));
  
    const bounds = mapRef.current
      ? mapRef.current
          .getMap()
          .getBounds()
          .toArray()
          .flat()
      : null;
  
    const { clusters, supercluster } = useSupercluster({
      points,
      bounds,
      zoom: viewport.zoom,
      options: { radius: 75, maxZoom: 16}
    });
  
    function FindMergeColor(data) {
        let S = "/merdeGreen.svg";

        //console.log(JSON.stringify(data));
        Promise.all(data.map(Item => {
            console.log(JSON.stringify(Item.properties));
            if(Item.properties.status === 'R' ) {
                S = "/merdeRed.svg"
            } else if(S === "/merdeGreen.svg" && Item.properties.status === 'Y') {
                S = "/merdeYellow.svg"
            }      
            return S;
        })).then((res) => {
            //console.log(res)
        });
        return S;

    }
    
    return (
          <ReactMapGL
            {...viewport}
            maxZoom={16}
            mapboxApiAccessToken="pk.eyJ1Ijoib2xlcnUiLCJhIjoiY2tobmcwb2pyMHA0cDJwcXFrZnJwNDZnaSJ9.bRAY-DErXfYbb_6yLxy9ng"
            mapStyle='mapbox://styles/mapbox/satellite-v9'  //streets-v11'
            onViewportChange={newViewport => {
              setViewport({ ...newViewport });
            }}
            ref={mapRef}
          >
            {clusters.map(cluster => {
              const [longitude, latitude] = cluster.geometry.coordinates;
              const {
                cluster: isCluster,
                point_count: pointCount
              } = cluster.properties;
    
              if (isCluster) {
                return (
                  <Marker
                    key={`cluster-${cluster.id}`}
                    latitude={latitude}
                    longitude={longitude}
                    offsetLeft={-45}
                    offsetTop={-45}
                  >
                    <button 
                        className="crime-marker"
                        onClick={() => {
                            const expansionZoom = Math.min(
                            supercluster.getClusterExpansionZoom(cluster.id),
                            20
                            );
        
                            setViewport({
                            ...viewport,
                            latitude,
                            longitude,
                            zoom: expansionZoom,
                            transitionInterpolator: new FlyToInterpolator({
                                speed: 2
                            }),
                            transitionDuration: "auto"
                            });
                        }}
                    >
                        <img
                            src={FindMergeColor(supercluster.getLeaves(cluster.id))}  
                            style={{
                                width: 72,
                                height: 72
                            }}
                            alt="crime doesn't pay"     
                        />
                    </button>
                    <div className="crime-text">
                        {pointCount}
                    </div>
                  </Marker>
                );
              } else {    
                return (
                    <Marker
                    key={`merde-${cluster.properties.merdeId}`}
                    latitude={latitude}
                    longitude={longitude}
                    offsetLeft={-45}
                    offsetTop={-45}
                    >
                    <button className="crime-marker"
                     onClick={() => {
                                    setViewport({
                                        ...viewport,
                                        latitude,
                                        longitude,
                                        zoom: 16,
                                        transitionInterpolator: new FlyToInterpolator({
                                            speed: 2
                                        }),
                                        transitionDuration: "auto"
                                    });
                                }}>
                        <img 
                            src={(cluster.properties.status==='R' ? "/merdeRed.svg": (cluster.properties.status==='Y' ? "/merdeYellow.svg" : "/merdeGreen.svg"))}
                            style={{
                                width: 72,
                                height: 72
                            }}
                            alt="crime doesn't pay"     
                        />
                    </button>
                    <div className="crime-text">
                        {cluster.properties.merdeNick}
                    </div>
                    </Marker>
                );
              }
            })}
          </ReactMapGL>
      );
        
};

export default MapboxGLMerdeMap