import React from "react";
//import AuthService from "../services/auth.service";
import DataService from "../services/data.service";
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: 180,
    },
    paper: {
        color: 'black',
        backgroundColor: 'white',
        borderRadius: 5,
    },
    paramControl: {
        margin: theme.spacing(1),
        width: 400,
    },
    valueControl: {
        margin: theme.spacing(1),
        width: 100,
    },
    header: {
        margin: theme.spacing(2),
        width: '100%',
    },
    picker: {
        margin: 5,
    },
    myBtn: {
        margin: 10,
        width: 100,
        align: 'bottom',
    },
}));

export default function SendMQTT({ myContent }) {
    const classes = useStyles();
    let clusterID = localStorage.getItem('ktas.lastReportClusterID');
    if (clusterID === null) {
        clusterID = '';
    }

    let myParam = localStorage.getItem('ktas.lastReportParam');
    if (myParam === null) {
        myParam = '';
    }

    const [state, setState] = React.useState({
        clusterID: clusterID,
        myParam: myParam,
        value: 0,
    });

    const handleChange = (event) => {
        const name = event.target.name;
        if (name === 'clusterID') {
            localStorage.setItem('ktas.lastReportClusterID', event.target.value);
        }
        if (name === 'param') {
            localStorage.setItem('ktas.lastReportParam', event.target.value);
        }
        setState({
            ...state,
            [name]: event.target.value,
        });
    };

    const handleClick = () => {
        //alert('Button ' + state.clusterID + ':' + state.param + ' = ' + state.value + ' clicked');
        //<div>{'{"ID":0,"'}{state.param}{'":'}{state.value}{'}'}</div>
        //DataService.MQTTPub(state.clusterID,'{"ID":' + state.lamp + ',"' + state.param + '":' + state.value + '}');
        if(state.param === "PWM") {
            DataService.MQTTPub(state.clusterID,'{"ID":' + state.lamp + ',"' + state.param + '":' + state.value + '}');
        } else {
            DataService.MQTTPub(state.clusterID,'{"ID":' + state.lamp + ',"status":"' + (state.param === "statusG" ? "G" : (state.param === "statusY" ? "Y" : "R")) + '"}');
        }
    }

    return (
        <div>
            <Grid container justify="left" className={classes.paper}>
                <div className={classes.header}>
                    <h3>Systemkonfigurator</h3>
                </div>
                <span className={classes.picker}>
                <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="IMEI-native-simple">Skap</InputLabel>
                        <Select
                            native
                            value={state.clusterID}
                            onChange={handleChange}
                            inputProps={{
                                name: 'clusterID',
                                id: 'IMEI-native-simple',
                            }}                                >
                            <option aria-label="None" value="" />
                            {myContent && myContent.map(Item => (
                                <option value={Item.IMEI}>{Item.IMEI}</option>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="IMEI-native-simple">Lampe</InputLabel>
                        <Select
                            native
                            value={state.lamp}
                            onChange={handleChange}
                            inputProps={{
                                name: 'lamp',
                                id: 'IMEI-native-simple',
                            }}                                >
                            <option aria-label="None" value="" />
                            <option aria-label="None" value="" />
                            <option value="0">Alle</option>
                            <option value="1">Lampe 1</option>
                            <option value="2">Lampe 2</option>
                            <option value="3">Lampe 3</option>
                            <option value="4">Lampe 4</option>
                            <option value="5">Lampe 5</option>
                            <option value="6">Lampe 6</option>
                        </Select>
                    </FormControl>
                    <FormControl className={classes.paramControl}>
                        <InputLabel htmlFor="IMEI-native-simple">Parameter</InputLabel>
                        <Select
                            native
                            value={state.param}
                            onChange={handleChange}
                            inputProps={{
                                name: 'param',
                                id: 'name-native-simple',
                            }}                                >
                            <option aria-label="None" value="" />
                            <option value="PWM">PWM - Lysstyrke [%]</option>
                            <option value="statusG">Sett grønn status</option>
                            <option value="statusY">Sett gul status</option>
                            <option value="statusR">Sett rød status</option>
                        </Select>
                    </FormControl>
                    <FormControl className={classes.valueControl}>
                        <TextField
                            id="filled-number"
                            label="Verdi"
                            type="number"
                            name="value"
                            value={state.value}
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="filled"
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <div  className={classes.myBtn}>
                            <Button variant="outlined" onClick={handleClick}>Send</Button>
                        </div>
                    </FormControl>
                </span>
            </Grid>
        </div>
    )
};
