import React from "react";
import MapboxGLMerdeMap from "./MapboxGLMerdeMap";
import LampCard2 from "./lampCard2";

/*
function ReturnSumStatus(A, B) {
    let newA = (A ? A.substring(1, 2) : '-')
    let newB = (B ? B.substring(1, 2) : '-')

    if ((newA === 'R') || (newB === 'R'))
        return 'R'
    else if ((newA === 'r') || (newB === 'r'))
        return 'r'
    else if ((newA === 'Y') || (newB === 'Y'))
        return 'Y'
    else if ((newA === 'y') || (newB === 'y'))
        return 'y'
    else if ((newA === 'g') || (newB === 'g'))
        return 'g'
    else
        return 'G'
}
*/
function ToUpper(S) {
    return S.toUpperCase()
}

export default function MySystemsForm({ myContent, myClusters }) {
    return (
        <>
            <span className="viewcard22">
                <MapboxGLMerdeMap myContent={myContent} />
            </span>
            {myClusters && myClusters.map(Item => (
                <span className="viewcard21">
                    <h2>{Item.nick}</h2>
                    <h5>{Item.name}</h5>
                        {Item.systems && Item.systems.map(sys => (
                            <>
                                <h4>
                                    <img 
                                        src={(sys.status[1]==='R' ? "/merdeRed.svg": (sys.status[1]==='Y' ? "/merdeYellow.svg" : "/merdeGreen.svg"))}
                                        style={{
                                            width: 36,
                                            height: 36
                                        }}
                                        alt="crime doesn't pay"     
                                    />
                                    {sys.nick} 
                                </h4>
                                {sys.status} {sys.IMEI}
                                <div className='lamp-group-container'>
                                    {sys.data && sys.data.map(Lamp => (
                                        <LampCard2 IMEI={sys.IMEI} data={Lamp} />
                                    ))}
                                </div>    
                            </>
                        ))}
                </span>
            ))}

        </>
    )
};
